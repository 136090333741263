@import "_mixins.scss";
@import "_variables";
@import "_functions";

@font-face {
  font-family: 'Poppins';
  src: url('../../public/fonts/Poppins.ttf') format('truetype'),
}
@font-face {
  font-family: 'KumbhSans';
  src: url('../../public/fonts/KumbhSans.ttf') format('truetype'),
}
@font-face {
  font-family: 'Assistant';
  src: url('../../public/fonts/Assistant.ttf') format('truetype'),
}
@font-face {
  font-family: "OpenSans";
  src: url('../../public/fonts/OpenSans.ttf') format('truetype'),;
}
//global styles


