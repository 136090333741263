@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/index";
body {
    font-family: $font-family;
    font-size: $font-size;
    margin: 0;
  }
  p {
    margin: 0;
  }

  
  a {
    text-decoration: none;
    color: $primary-color;
  }

