@import "src/styles/index.scss";

.importContacts {
  padding: 2rem 1rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &Container {
    width: 100%;
    max-width: 768px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
}

.logo {
  width: 200px;
  height: auto;
}

// Tabs
.tabsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  // gap: 1rem;
  padding: 0 1rem;
  margin-top: 1rem;
}

.tab {
  flex: 1;
  max-width: 200px;
  padding: 0.75rem 1rem;
  background: transparent;
  border: none;
  height: 40px;
  font-size: 1rem;
  color: #718096;
  cursor: pointer;
  position: relative;
  text-align: right;
  transition: all 0.2s ease;

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 10px;
    background-color: #E2E8F0;
    transition: background-color 0.2s ease;
  }

  &.activeTab {
    color: #000;
    font-weight: 600;

    &::after {
      background-color: #F8CBAD;
      height: 8px;
    }
  }
}

// Search
.searchContainer {
  width: 100%;
  padding: 0 1rem;
  position: relative;
  display: flex;
  align-items: center;
}

.searchInput {
  width: 100%;
  height: 28px;
  padding: 0.75rem 3rem 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  direction: rtl;

  &::placeholder {
    color: #a0aec0;
  }

  &:focus {
    outline: none;
    border-color: #4a5568;
  }
}

.sectionHeader {
  text-align: center;
  font-size: 1.125rem;
  color: #000;
  // margin: 1rem 0;
  width: 100%;
  font-weight: 500;
  // margin-bottom: -4px;
  // margin-top: -10px;
}


.iconWrapper {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: #718096;
  pointer-events: none;
  z-index: 1;
}

// Contact List
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
}

.contactsDetails {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contacts {
  flex: 1;
  background: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

  .contactNameContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    height:27px;

    .name {
      flex: 1;
      font-size: 0.875rem;
      font-weight: 600;
      color: black;
      text-align: right;
    }
  }
}

.arrowIcon {
  width: 12px;
  height: 6px;
  transition: transform 0.2s ease;

  &Rotate {
    transform: rotate(180deg);
  }
}

// Recommended List
.recommendedList {
  margin-right: 2rem;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: auto 0 0 0;
  align-items: stretch;
}

.recommendedCard {
  margin-top:10px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 1rem;

  .recommendedListItems {
    display: flex;
    flex-direction: column;
    gap: -0.25rem;
    text-align: right;
    direction: rtl;
    padding: 0.5rem;
  }

  .recommendedListItem {
    font-size: 1rem;
    line-height: 1.5;
    color: #000;
    margin: 0;

    &:first-child {
      font-weight: 600;
    }

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .recommendedBy {
    font-size: 0.875rem;
    color: black;
    // margin-top: 0.5rem;
  }
}

.noRecommendations {
  text-align: right;
  color: #666;
  padding: 1rem;
  margin: 0 2rem 0 0;
  box-sizing: border-box;
}


// Button
.btn {
  width: 100%;
  max-width: 200px;
  padding: 0.75rem 1.5rem;
  background-color: $primary-color;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 2rem;

  &:hover {
    background-color: darken($primary-color, 5%);
  }
}

// Thank You State
.thankYou {
  text-align: center;
  padding: 2rem;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

// Invalid Code State
.invalidCode {
  min-width: 100wh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    font-weight: 700;
    direction: rtl;
    max-width: 600px;
    font-size: 1.5rem;
  }
}

// Delete icon
.minus {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

@media screen and (max-width: 480px) {
  .importContactsContainer {
    padding: 0 0.5rem;
  }

  .tab {
    padding: 0.1rem;
    font-size: 0.875rem;
  }

  .contacts {
    padding: 0.5rem;
  }

  .recommendedCard {
    padding: 0.75rem;
  }
  .container {
    width: 330px;
  }
}

.deletePopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.02);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &Container {
    width: 90%;
    max-width: 400px;
    background: white;
    border: solid grey 2px;
    border-radius: 12px;
    position: relative;
    padding: 24px;
    direction: ltr;  // Force LTR for container positioning
  }

  &Close {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 4px;
    cursor: pointer;
    color: #666;
    direction: rtl;  // Force LTR for the button
    z-index: 2;     // Ensure it's above other content
  }

  &Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    direction: rtl;  // Keep RTL for the content
  }
  &Title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
    width: 100%;
    text-align: center;
  }

  &Text {
    font-size: 16px;
    margin-bottom: 16px;
    width: 100%;
    text-align: center;

    &ContactName {
      font-weight: bold
    }
    
    &:last-of-type {
      margin-bottom: 32px;
    }
  }

  &ButtonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
    margin-top: 16px;
    *.loader {
      padding: 0;
    }
  }

  &Button {
    flex: 1;
    max-width: 160px;
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    
    &Confirm {
      background-color: #F8CBAD;
      color: black;
      
      &:hover {
        background-color: darken(#F8CBAD, 5%);
      }
    }
    
    &Cancel {
      background-color: transparent;
      border: 1px solid #FF6B00;
      color: #FF6B00;
      
      &:hover {
        background-color: rgba(#FF6B00, 0.05);
      }
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 2rem;
  padding-top: 1rem;
}

.svgLoader {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
