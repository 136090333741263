@import "src/styles/index";

.thankyou {
    @include center(center);
    flex-direction: column;
    height: 100vh;
    p {
        font-weight: 700;
        &:first-child {
             font-size: 24px;
             margin-bottom: 12px;
             font-family: $font-family-kumbh;
        }
    }
}