@import "src/styles/index";

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 1;

  button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: $font-family;
    width: 49px;
    height: 27px;
    background-color: $white;
    border: 1px solid $outline;
    border-radius: 5px;
    cursor: pointer;
    .rotateArrow {
      display: inline-block;
      transform: rotate(180deg);
    }
  }

  ul {
    font-family: $font-family;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 5px;
    padding: 0;
    list-style: none;
    border: 1px solid $outline;
    border-radius: 5px;
    width: 46px;
    background-color: $white;

    li {
      padding: 8px 12px;
      cursor: pointer;

      &:hover {
        background-color: $light-gray-1;
      }
    }
  }
}