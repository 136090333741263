@import "src/styles/index.scss";
.container {
  padding-bottom: 2rem;
  .title {
    font-family: $font-family;
  }
  .resultsPerPage {
    font-family: $font-family;
    color: $black;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    span {
      margin-right: 9px;
    }
  }
  .userTable {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.011em;
    text-align: left;

    margin-top: 30px;
    .tableHeader {
      display: flex;
      justify-content: space-between;
      padding-left: 34px;
      .headerItem {
      }
    }
    .tableBody {
      margin-top: 13px;
      .cells {
        width: 100%;
        .items {
          height: 56px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid $outline;
          position: relative;
          margin-top: 17px;
          padding: 19px 0;
          padding-left: 34px;
          border-radius: 10px;
          font-family: $font-family;
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.011em;
          text-align: left;
          color: $black;
          .item {
            font-family: $font-family;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.011em;
            text-align: left;
            padding-right: 20px;
          }
          .phoneNum {
            margin-left: 9.5rem;
          }
          .nameBlockWrapper {
            display: flex;
            @media (max-width: 768px) {
              display: block;
            }
          }
          .dots {
            position: absolute;
            right: 0;
            padding-left: 20px;
            cursor: pointer;
            width: 25px;
            height: 25px;
            .img {
              display: flex;
              justify-content: center;
            }
          }
          .Img {
            position: absolute;
            right: 1rem;
          }
          .blockBtn {
            width: 109px;
            height: 60px;
            box-sizing: border-box;
            font-family: $font-family-poppins;
            color: $black;
            position: absolute;
            right: -40px;
            top: 45px;
            background: $white !important;
            border: 1px solid $lighter-gray;
            padding: 20px 11px 21px 18px;
            outline: 0;
            border-radius: 16px;
            background: none;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            z-index: 999;
            cursor: pointer;

            button {
              background: none;
              border: 0;
              outline: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
    .tableFooter {
      display: flex;
      justify-content: space-between;
      margin-top: 49px;
      .pages {
        display: flex;
        .back {
          @include center(center);
          background: $light-gray-1;
          margin-right: 7px;
          border-radius: 5px;
          width: 27px;
          height: 27px;
          cursor: pointer;
        }
        .forward {
          @include center(center);
          background: $light-gray-1;
          margin-left: 7px;
          border-radius: 5px;
          width: 27px;
          height: 27px;
          transform: rotate(180deg);
          cursor: pointer;
        }
        .number {
          @include center(center);
          border: 1px solid $light-gray-1;
          color: $black;
          border-radius: 5px;
          width: 25px;
          height: 25px;
          font-weight: 400;
          size: 14px;
        }
      }
    }
  }
}
