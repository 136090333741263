@import "src/styles/index.scss";

.newBusinessForm {
  width: 500px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 0px;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    margin-top: 18px;

    .labelLoaderWrapper {
      display: flex;

      .spinner {
        margin-left: 1rem;
        left: 100%;
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-top: 4px solid $black;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 1s linear infinite;
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .error {
      color: $error;
    }

    .selectedCategories {
      display: flex;
      flex-wrap: wrap;
      width: 320px;
    }

    .selectedCategory {
      display: flex;
      justify-content: center;
      margin-right: 10px;
      font-size: 16px;
      color: $gray;
      margin: 5px;
      padding: 2px 4px;
      border: 1px solid $light-gray-1;

      img {
        width: 20px;
        cursor: pointer;
      }
    }
  }

  h2 {
    font-family: $font-family;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.newBusinessForm label {
  font-family: $font-family;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.176px;
  text-align: left;
}

.newBusinessForm input {
  width: 320px;
  height: 49px;
  border-radius: 10px;
  border: 1px solid $white-3;
  padding-left: 20px;

  &::placeholder {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    // color: $light-gray;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
}

.formActions {
  display: flex;
  margin-top: 38px;

  .save,
  .cancel {
    margin-right: 29px;
    width: 168px;
    height: 48px;
    border-radius: 8px;
    cursor: pointer;

    @include center(center);

    button {
      font-size: 14px;
      font-weight: 700;
      font-family: $font-family;
      background: none;
      outline: none;
      cursor: pointer;
    }
  }

  .save {
    background: $primary-color;

    button {
      color: $white;
    }
  }

  .cancel {
    border: 1px solid $gray-darker-2;
    color: $black;
  }
}

.listBox {
  border: 1px solid #F2F2F2;
  border-top: none;
  margin-top: 2px;
  cursor: pointer;
  width: 21.2rem;

  ul {
    width: 100%;
    padding: 0;
    margin: 0;
    min-height: 100%;
    max-height: 10rem;
    overflow-y: scroll;
    overflow-x: hidden;

    li {
      list-style: none;
      padding: 7px 0;
    }

    .listItem {
      padding-left: 10px;
      width: calc(100% - 10px);

      &:hover {
        background: $light-gray-1;
      }
    }
  }

}