$primary-color: #5061FF;
$secondary-color: #6c757d;
$gray: #6A6A6A;
$lighter-gray:#F0F0F0;
$font-family: Assistant;
$font-family-kumbh: KumbhSans;
$font-family-poppins: Poppins;
$font-family-OpenSans:OpenSans;
$font-size: 16px;
$text-primary:#374251;
$light-gray-1: #E0E0E0;
$light-gray:#929EAE;
$lighter-gray-2: #7C7C7D;
$gray-dark:#464646;
$gray-darker:#46474B;
$gray-border:#7C7D7D;
$gray-darker-2: #3A4B5C;
$white:#fff;
$black:#000;
$black-shade:#282828;
$outline: #EBEBEB;
$error: #FF5353;
$white-2: #ECF1F5;
$white-3: #F2F2F2;
$block-text : #848484;
