@import "src/styles/index";

.button {
  // width: 200px;
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  // transition: background-color 0.2s ease-in-out;
  //     &:hover {
  //     background-color: lighten($primary-color, 30%);
  //   }
  
  //   &.secondary {
  //     background-color: $secondary-color;
  
  //     &:hover {
  //       background-color: lighten($secondary-color, 30%);
  //     }
  //   }
}